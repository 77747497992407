<template>
  <div class="package-region-item">
    <strong class="package-region-item--name">
      <b>{{ regionName }}</b>
    </strong>

    <ul class="package-region-item--services d-flex align-items-center">
      <li
        v-for="(service, idx) in services"
        :key="service.name"
        class="package-region-item--services-item"
        :class="{
          'me-2': idx < services.length - 1
        }"
      >
        <div class="d-flex align-items-center">
          <div class="me-1">
            <SvgIcon
              v-if="service.status"
              icon="checkmark"
              class="color-success"
            />
            <SvgIcon v-else icon="tick" class="color-danger" />
          </div>

          <span class="secondary-text">{{ service.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "PackageRegionItem",
  components: { SvgIcon },
  props: {
    calls: { type: Boolean, default: true },
    sms: { type: Boolean, default: true },
    mms: { type: Boolean, default: true },
    fax: { type: Boolean, default: true },
    regionName: { type: String, required: true }
  },
  data() {
    return {
      services: [
        {
          name: "calls",
          status: this.calls
        },
        {
          name: "sms",
          status: this.sms
        },
        {
          name: "mms",
          status: this.mms
        },
        {
          name: "fax",
          status: this.fax
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.package-region-item {
  padding: 0.7rem 0;
  &--name {
    font-size: 1.07rem;
  }
  &--services {
    margin-top: 5px;
    &-item {
      font-size: 9px;
      .secondary-text {
        font-size: 1em;
      }
    }
  }
}
</style>
