<template>
  <PackageEditModal
    title="Los Angeles, CA"
    :opened="packageModalOpened"
    :initial-plans="[]"
    :active-package-id="activePackageId"
    @edit="onPackageEditEnd"
    @delete="onPackageDelete"
    @hide="hidePackageModal"
  />

  <Dialog
    :title="null"
    :opened="deletePackageModalOpened"
    @hide="deletePackageId = null"
  >
    <div class="d-flex flex-column justify-content-center">
      <h3 class="text-center">Los Angeles, CA</h3>
      <p class="text-center mt-2 pt-1">
        Note, this package has been already associated with users numbers. You
        sure you want to remove it.
      </p>

      <Btn size="xl" type="danger" class="mt-4">Yes, remove!</Btn>
    </div>
  </Dialog>

  <TableRow class="user-table-row">
    <template #content>
      <Accordion>
        <template #heading>
          <div
            class="d-flex align-items-center justify-content-between accordion-header"
          >
            <TableCell size="xl">
              <Country id="usa" name="United States" />
            </TableCell>
            <TableCell size="smL">
              <strong>50 packages</strong>
            </TableCell>
            <TableCell size="lg">
              <strong>14 May 2020 at 1:54 am</strong>
            </TableCell>
            <TableCell size="lg">
              <UserInfo
                :avatar="require('@/assets/img/sophie.png')"
                name="Úrsula Corberó"
              />
            </TableCell>
          </div>
        </template>
        <template #body>
          <div class="table-accordion-body pb-5 pt-4">
            <div class="row mb-1">
              <div class="col-4 col-xxxl-3">
                <PackageCard
                  :id="id()"
                  title="Country Packages"
                  type="country"
                  @packageEditStart="onPackageEditStart"
                  @packageEditEnd="onPackageEditEnd"
                  @packageDelete="onPackageDelete"
                  @onRegionSelect="onStateSelect"
                />
              </div>

              <transition name="fade">
                <div v-if="activeState" class="col-4 col-xxxl-3">
                  <PackageCard
                    :id="id()"
                    title="California Packages"
                    type="state"
                    @packageEditStart="onPackageEditStart"
                    @packageEditEnd="onPackageEditEnd"
                    @packageDelete="onPackageDelete"
                    @onRegionSelect="onCitySelect"
                  />
                </div>
              </transition>
              <transition name="fade">
                <div v-if="activeCity" class="col-4 col-xxxl-3">
                  <PackageCard
                    :id="id()"
                    title="Los Angeles Packages"
                    type="city"
                    error="To add a new package you need to remove existing one in California state!"
                    @packageEditStart="onPackageEditStart"
                    @packageEditEnd="onPackageEditEnd"
                    @packageDelete="onPackageDelete"
                    @onRegionSelect="onCitySelect"
                  />
                </div>
              </transition>
            </div>
          </div>
        </template>
      </Accordion>
    </template>
  </TableRow>
</template>

<script>
import TableRow from "@/components/Table/TableRow";
import Accordion from "@/components/ui/Accourdion/Accordion";
import PackageCard from "@/components/Packages/PackageCard";
import TableCell from "@/components/Table/TableCell";
import Country from "@/components/ui/Country/Country";
import UserInfo from "@/components/UserInfo/UserInfo";
import PackageEditModal from "@/components/Packages/PackageEditModal";
import { getId } from "@/utills/getId";
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import Btn from "@/components/ui/Buttons/Button";

export default {
  name: "VirtualPackageCountryRow",
  components: {
    Btn,
    Dialog,
    TableRow,
    Accordion,
    PackageCard,
    TableCell,
    Country,
    UserInfo,
    PackageEditModal
  },
  data() {
    return {
      activePackageId: null,
      activeState: null,
      activeCity: null,
      packageModalOpened: false,
      deletePackageId: null,
      deletePackageModalOpened: false
    };
  },

  watch: {
    activePackageId(id) {
      this.packageModalOpened = !!id;
    },
    deletePackageId(id) {
      this.deletePackageModalOpened = !!id;
    },
    deletePackageModalOpened(opened) {
      if (opened) {
        this.hidePackageModal();
      }
    },
    packageModalOpened(opened) {
      if (opened) {
        this.deletePackageModalOpened = false;
        this.deletePackageId = null;
      }
    }
  },

  methods: {
    id() {
      return getId("package"); // fixme: remove in prod. For test
    },
    onPackageDelete(id) {
      console.log("Delete package", id);
      this.deletePackageId = id;
    },

    onPackageEditStart(id) {
      this.activePackageId = id;
    },

    onPackageEditEnd(data) {
      this.activePackageId = null;

      if (data) {
        console.log("New package date", data);
      }
    },

    hidePackageModal(data = null) {
      this.onPackageEditEnd(data);
    },

    // todo передавать как пропсы
    onStateSelect(id) {
      this.activeState = id;
      if (!id) {
        this.onCitySelect(null);
      }
    },

    onCitySelect(id) {
      this.activeCity = id;
    }
  }
};
</script>

<style scoped></style>
