<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-12">
        <SelectInput
          id="planType"
          label="Plan Type"
          :options="planTypes"
          @select="planTypeSelect"
        />
      </div>

      <div class="col-12 mt-3">
        <SelectInput
          id="planValidity"
          label="Plan Validity"
          :options="planValidity"
          @select="planValiditySelect"
        />
      </div>

      <div class="col-6 mt-3">
        <AppInput
          id="minutes"
          v-model="minutes"
          input-type="material"
          label="Minutes"
        />
      </div>
      <div class="col-6 mt-3">
        <AppInput id="sms" v-model="sms" input-type="material" label="SMS" />
      </div>

      <div class="col-6 mt-3">
        <AppInput
          id="defaultPrice"
          v-model="defaultPrice"
          input-type="material"
          label="Default Price"
          static-text="$"
          type="number"
        />
      </div>

      <div class="col-6 mt-3">
        <SelectInput
          id="discounts"
          label="Discount"
          :options="discounts"
          @select="discountSelect"
        />
      </div>

      <div class="col-12 mt-3">
        <AppInput
          id="Price-with-Discount"
          v-model="priceWithDiscount"
          input-type="material"
          label="Price-with-Discount"
          static-text="$"
          readonly
          type="number"
        />
      </div>

      <div class="col-12 mt-3">
        <label for="required-verification" class="d-flex align-items-center">
          <AppCheckbox
            id="required-verification"
            v-model="requiredVerification"
          />
          <strong class="ms-2 sm"
            >Available for numbers that require verification</strong
          >
        </label>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6">
        <Btn type="secondary" size="xl">Add Plan</Btn>
      </div>
      <div class="col-6">
        <Btn type="default-transparent" size="xl" @click="$emit('cancel')">
          Cancel
        </Btn>
      </div>
    </div>
  </form>
</template>

<script>
import AppInput from "@/components/Form/AppInput";
import SelectInput from "@/components/Form/SelectInput";
import AppCheckbox from "@/components/Form/AppCheckbox";
import Btn from "@/components/ui/Buttons/Button";
import { getDiscountPrice } from "@/utills/getDiscountPrice";
export default {
  name: "AddPackageForm",
  // eslint-disable-next-line vue/no-unused-components
  components: { Btn, AppCheckbox, SelectInput, AppInput },
  data() {
    return {
      requiredVerification: true,
      planTypes: [
        {
          id: 1,
          name: "Landline & Mobile",
          selected: true
        },
        {
          id: 2,
          name: "Mobile Only",
          selected: false
        },
        {
          id: 3,
          name: "Landline Only",
          selected: false
        }
      ],
      planValidity: [
        {
          id: 1,
          name: "1 month (one-time payment)",
          selected: true
        },
        {
          id: 2,
          name: "3 months (one-time payment)",
          selected: false
        },
        {
          id: 3,
          name: "1 YEAR (one-time payment)",
          selected: false
        },
        {
          id: 4,
          name: "1 month (subscription)",
          selected: false
        },
        {
          id: 5,
          name: "1 month (one-time payment)",
          selected: false
        }
      ],
      minutes: "",
      sms: "",
      defaultPrice: "",
      discounts: [
        {
          id: 1,
          selected: true,
          count: 10
        },
        {
          id: 2,
          selected: false,
          count: 15
        }
      ].map(d => ({ ...d, name: `${d.count}%` }))
    };
  },
  computed: {
    priceWithDiscount() {
      if (!this.defaultPrice) {
        return 0;
      }

      const percent =
        this.discountes.find(({ selected }) => selected)?.count ?? 0;

      return getDiscountPrice(this.defaultPrice, percent);
    }
  },
  methods: {
    planTypeSelect(id) {
      this.planTypes.forEach(plan => {
        plan.selected = plan.id === id;
      });
    },
    planValiditySelect(id) {
      this.planValidity.forEach(plan => {
        plan.selected = plan.id === id;
      });
    },

    discountSelect(id) {
      this.discountes.forEach(discount => {
        discount.selected = discount.id === id;
      });
    }
  }
};
</script>

<style scoped></style>
