<template>
  <Dialog :title="title" v-bind="$props" @hide="$emit('hide')">
    <template #tabs>
      <tabs v-model="selectedTab">
        <tab
          v-for="(tab, i) in tabs"
          :key="`t${i}`"
          class="tab "
          :val="tab"
          :label="tab"
          :indicator="true"
        />
      </tabs>
    </template>
    <template #tabs-content>
      <div ref="content-sensor">
        <tab-panels v-model="selectedTab" :animate="true">
          <tab-panel :val="tabs[0]" class="overlay-overflow">
            <transition name="max-height">
              <transition name="max-height" mode="out-in">
                <div v-if="selectedTab === tabs[0]" class="pt-5">
                  <div v-if="!packageFormActive">
                    <ul class="plans-list">
                      <li
                        v-for="({ planValidity, planId, planServiceTypes },
                        idx) in plans"
                        :key="planId"
                        class="mb-2"
                        :style="{
                          position: 'relative',
                          zIndex: plans.length + idx
                        }"
                      >
                        <PackagePlan
                          :validity="planValidity"
                          :plan-id="planId"
                          :services="planServiceTypes"
                          @edit="onAddPackage"
                        />
                      </li>
                    </ul>
                    <div class="mt-3">
                      <Btn
                        class="w-100"
                        type="primary-border-dashed"
                        size="lg"
                        @click="onAddPackage"
                      >
                        <BtnContent icon="plus" label="Add plan" />
                      </Btn>
                    </div>

                    <div class="row no-gutters mt-4 pt-2">
                      <div class="col-6">
                        <Btn type="secondary" size="xl">Save </Btn>
                      </div>
                      <div class="col-6">
                        <Btn
                          type="danger-transparent"
                          size="xl"
                          @click="$emit('delete', id)"
                        >
                          Remove
                        </Btn>
                      </div>
                    </div>
                  </div>

                  <AddPackageForm v-else @cancel="onChangePackageEnd" />
                </div>
              </transition>
            </transition>
          </tab-panel>
          <tab-panel :val="tabs[1]">
            <transition name="max-height">
              <ul
                v-if="selectedTab === tabs[1]"
                class="plan-features pt-5 pb-5"
              >
                <li
                  v-for="feature in planFeatures"
                  :key="feature.title"
                  class="plan-features--item"
                >
                  <SettingsItem
                    :id="feature.title"
                    v-model="feature.selected"
                    :title="feature.title"
                  />
                </li>
              </ul>
            </transition>
          </tab-panel>
        </tab-panels>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/ui/Modal/Dialog/Dialog";
import PackagePlan from "@/components/Packages/PackagePlan";
import Btn from "@/components/ui/Buttons/Button";
import BtnContent from "@/components/ui/Buttons/BtnContent";
import { getId } from "@/utills/getId";
import AddPackageForm from "@/components/Packages/AddPackageForm";
import SettingsItem from "@/components/Settings/SettingsItem";
const tabs = ["Plans", "Features"];
export default {
  name: "PackageEditModal",
  components: {
    SettingsItem,
    AddPackageForm,
    BtnContent,
    Btn,
    PackagePlan,
    Dialog
  },
  emits: ["edit", "delete", "add", "hide"],
  props: {
    id: { type: String, default: getId() }, // todo get actual id
    title: { type: String, required: true },
    initialPlans: { type: Array, required: false, default: () => [] },
    activePackageId: { type: String, required: true }
  },
  data() {
    return {
      tabs,
      selectedTab: tabs[0],
      packageFormActive: false,
      planFeatures: [
        {
          title: "Unlimited incoming Calls, SMS, MMS",
          selected: true
        },
        {
          title: "Unlimited Voicemail & Call recordings",
          selected: true
        },
        {
          title: "Outgoing Calls",
          selected: true
        },
        {
          title: "Outgoing SMS",
          selected: true
        },
        {
          title: "Outgoing MMS",
          selected: true
        },
        {
          title: "Outgoing FAX",
          selected: false
        }
      ],
      modalHeight: 0
    };
  },
  computed: {
    plans() {
      return this.$store.getters.plansByRegionId(this.activePackageId);
    }
  },
  methods: {
    onAddPackage() {
      this.packageFormActive = true;
    },
    onChangePackageEnd() {
      this.packageFormActive = false;
    }
  }
};
</script>

<style lang="scss">
.plan-features--item {
  max-width: 280px;
  .settings-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
