<template>
  <Card v-bind="$props" :title="null" class="package-card pb-4">
    <div ref="scrollArea" class="package-card-wrapper">
      <div class="package-card--head">
        <div v-if="!error">
          <h2 class="package-card--title">{{ title }}</h2>

          <ul v-if="packagesList.length" class="packages-list pt-1">
            <li
              v-for="pack in packagesList"
              :key="pack.packageId"
              class="packages-list--item"
            >
              <EsimPackageCard
                :id="pack.packageId"
                :title="pack.packageName"
                @edit="packId => $emit('packageEditStart', packId)"
                @delete="packId => $emit('packageDelete', packId)"
              />
            </li>
          </ul>
          <span v-else class="secondary-text">
            There are no packages added to this country.
          </span>
        </div>
        <TextErrorMessage v-else type="bg" :message="error" />
        <div class="mt-3 pt-3">
          <Btn
            class="w-100"
            type="primary-border-dashed"
            size="lg"
            :disabled="!!error"
            @click="onAddPackage"
          >
            <BtnContent icon="plus" label="Add Package" />
          </Btn>
        </div>
      </div>

      <div v-if="regionsList.length" class="package-card--body">
        <strong>
          <b>Or, select a state below where you wish to add a package!</b>
        </strong>

        <div class="package-regions mt-3">
          <div class="mb-3">
            <SearchControl
              :on-search="() => {}"
              name="multiselect-search"
              field-type="inline-field"
              :placeholder="searchPlaceholder"
            />
          </div>

          <ul class="package-regions-list">
            <li
              v-for="{ id, name } in regionsList"
              :key="id"
              class="package-regions-list--item"
              :class="{
                isActiveRegion: id === selectedRegion
              }"
              @click="selectRegionHandler(id)"
            >
              <PackageRegionItem :region-name="name" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/ui/Card/Card";
import Btn from "@/components/ui/Buttons/Button";
import SvgIcon from "@/components/ui/SvgIcon";
import BtnContent from "@/components/ui/Buttons/BtnContent";
import SearchControl from "@/components/Form/SearchControl";
import PackageRegionItem from "@/components/Packages/PackageRegionItem";
import { useCustomScrollbar } from "@/mixins/useCustomScrollbar";
import TextErrorMessage from "@/components/Errors/TextErrorMessage";
import EsimPackageCard from "@/components/Packages/EsimPackageCard";

// Package = { id: String, name: String } //

export default {
  name: "PackageCard",
  components: {
    EsimPackageCard,
    TextErrorMessage,
    PackageRegionItem,
    SearchControl,
    BtnContent,
    Btn,
    Card,
    // eslint-disable-next-line vue/no-unused-components
    SvgIcon
  },
  mixins: [useCustomScrollbar],

  props: {
    id: { type: String, required: true },
    title: { type: String, required: false },
    packages: { type: Array, default: () => [] },
    error: { type: [String, null], required: false, default: null },
    type: {
      type: String,
      validator: t => ["country", "state", "city"].indexOf(t) !== -1,
      default: "state"
    }
  },
  emits: [
    "packageEditStart",
    "packageEditEnd",
    "packageDelete",
    "onRegionSelect"
  ],
  data() {
    return {
      selectedRegion: null
    };
  },
  computed: {
    searchPlaceholder() {
      return `Search for ${this.type}`;
    },
    packagesList() {
      return this.type === "state" // fixme is for test.
        ? this.$store.getters.packagesByRegionId(this.id)
        : [];
    },
    regionsList() {
      return this.type === "country"
        ? [...this.$store.getters["states"]]
        : this.type === "state"
        ? [...this.$store.getters["cities"]]
        : [];
    }
  },
  methods: {
    selectRegionHandler(id) {
      let addedId = this.selectedRegion === id ? null : id;
      this.selectedRegion = addedId;
      this.$emit("onRegionSelect", addedId);
    },
    onAddPackage() {
      this.$emit("packageEditStart", this.id);
    }
  }
};
</script>

<style lang="scss">
.package-card {
}
.package {
  &-card {
    padding: 0;
    .card-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-card-wrapper {
    max-height: 710px;
    overflow: auto;
    overflow-x: hidden;

    padding: 0 $card-padding-x;
  }

  &-card--title {
    padding-bottom: 1.1em;
  }
  &-card--head {
  }

  &-card--body {
    padding-top: 2.2em;
    margin-top: 1.8em;
    position: relative;

    &:before {
      content: "";
      width: calc(100% + #{$card-padding-x * 2});
      height: 1px;
      background-color: $border-color;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.package-regions-list--item {
  cursor: pointer;
  position: relative;
  .package-region-item {
    z-index: 2;
    position: relative;
  }
  &.isActiveRegion {
    &:before {
      content: "";
      width: 109%;
      height: 100%;
      background-color: $grey-400;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: $border-radius;
      z-index: 1;
    }
  }
}
</style>
